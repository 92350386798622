import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { Dialog, Button, Checkbox, Select } from 'sw-ui';

import DecisionButtons from '../../../components/DecisionButtons';

import { dateUtcFormat } from '../../../bi/utils/formatDate';
import { routeTaxi } from '../../../bi/utils/trip';
import MoneyFormat from '../../../bi/utils/money';

import { DECISIONBUTTONS } from '../../../bi/constants/decisionButtons';
import { DIALOGSTITLES, DIALOGS, TRIPSLABELS } from '../../../bi/constants/trips';
import COMPONENTS from '../../../bi/constants/components';
import { ACTION_IN_DATE_TIME_PATTERN } from '../../../constants/time';

import styles from '../styles/trip.module.scss';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 40,
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  marginTop: '28px',
};

class MoveItemDialogs extends Component {
  static propTypes = {
    // eslint-disable-next-line
    activeDialog: PropTypes.string.isRequired,
    trip: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    tripService: PropTypes.object.isRequired,
    closingDocuments: PropTypes.array.isRequired,
    moveToCompany: PropTypes.func.isRequired,
    moveTrip: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onNextStep: PropTypes.func.isRequired,
    moveOrders: PropTypes.func.isRequired,
    moveVouchers: PropTypes.func.isRequired,
    disableActive: PropTypes.func.isRequired,
    selectCompany: PropTypes.func.isRequired,
    waitResMove: PropTypes.bool.isRequired,
    waitResChange: PropTypes.bool.isRequired,
    isLeaveExcess: PropTypes.bool.isRequired,
    onChangeIsLeaveExcess: PropTypes.func.isRequired,
    onAgreeWithConditions: PropTypes.func.isRequired,
    surcharges: PropTypes.array.isRequired,
    subAccounts: PropTypes.array.isRequired,
    onChangeSubAccount: PropTypes.func.isRequired,
    resultTextMoveVouchers: PropTypes.string.isRequired,
    selectedSubAccount: PropTypes.number.isRequired,
    selectedCompanyId: PropTypes.number.isRequired,
    checkAllOperational: PropTypes.bool.isRequired,
    operationsInf: PropTypes.array.isRequired,
    documentNumber: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      cannotMoveTripDialog: false,
      dangerousMoveTripDialog: false,
      dangerousMoveTripOnlyIntegration: false,
      dangerousMoveTripWithIntegration: false,
      confirmMoveTripDialog: false,
      tripMovedDialog: false,
      cannotMoveOrdersDialog: false,
      cannotMoveVouchersDialog: false,
      dangerousMoveOrdersDialog: false,
      dangerousMoveOrdersOnlyIntegration: false,
      dangerousMoveOrdersWithIntegration: false,
      confirmMoveOrdersDialog: false,
      confirmMoveVouchersDialog: false,
      dialogWithIntegration: false,
      ordersMovedDialog: false,
      tripMovedDialogAlert: false,
      ordersMovedDialogAlert: false,
      agreeWithConditionsDialog: false,
      company: {
        label: '',
        suggests: [],
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const resetState = (active) => {
      const resetedState = {};

      // eslint-disable-next-line
      for (let key in this.state) {
        if (typeof this.state[key] === 'boolean') {
          resetedState[key] = false;
        }
      }

      resetedState[active] = true;

      this.setState(resetedState);
    };

    if (nextProps.activeDialog === '') {
      resetState();
    } else if (!(nextProps.activeDialog in this.state)) {
      // ничего не делаем
    } else {
      resetState(nextProps.activeDialog);
    }
  }

  handleSelectSuggest = (cValue, value) => {
    this.setState((state) => {
      // eslint-disable-next-line no-param-reassign
      state.company = {
        ...this.state.company,
        label: `${value.ShortCompanyName || value.CompanyName} (${value.CompanyId})`,
      };

      return state;
    });

    this.props.selectCompany(value.CompanyId);
  };

  handleChangeSuggest = (value) => {
    this.setState({
      company: {
        ...this.state.company,
        label: value,
      },
    }, async () => {
      const res = await this.props.tripService.autocompleteCompany(this.state.company.label);

      this.setState({
        company: {
          ...this.state.company,
          suggests: res,
        },
      });
    });
  };

  closeDialog = (dialogField) => {
    this.setState({
      [dialogField]: false,
    });

    this.props.disableActive();
  };

  closeTripMovedDialog = () => {
    const { trip: { AccountId }, companyId, moveToCompany } = this.props;

    moveToCompany(AccountId, companyId);
  };

  closeOrdersMovedDialog = () => {
    const { trip } = this.props;

    this.props.tripService.load(trip.AccountId, trip.TripId).then(() => {
      this.setState({
        ordersMovedDialog: false,
        ordersMovedDialogAlert: false,
      });

      this.props.disableActive();
    });
  };

  renderCheckbox = () => {
    const {
      surcharges,
      isLeaveExcess,
      onChangeIsLeaveExcess,
      checkAllOperational,
    } = this.props;

    if (!surcharges.length || checkAllOperational) return null;

    return (
      <div className={ styles.row }>
        <Checkbox
          theme={ COMPONENTS.CHECKBOX.THEME.MAIN }
          value={ isLeaveExcess }
          onChange={ onChangeIsLeaveExcess }
        >
          <div>{ TRIPSLABELS.IN_EXCESS }</div>
        </Checkbox>
      </div>
    );
  }

  renderOperationsInf = () => this.props.operationsInf.map((operation) => {
    const { Id, EventDate, Route } = operation;
    const [departure, arrival] = Route;

    const departurePlaceHtml = routeTaxi(departure);
    const arrivalPlaceHtml = routeTaxi(arrival);

    return (
      <div className={ styles['inf-operation'] } key={ Id }>
        <div>{ DIALOGSTITLES.TAXI_INFO.ID }: { Id }</div>
        <div className={ styles['inf-field'] }>{ DIALOGSTITLES.TAXI_INFO.FROM }: { departurePlaceHtml }</div>
        <div className={ styles['inf-field'] }>{ DIALOGSTITLES.TAXI_INFO.TO }: { arrivalPlaceHtml }</div>
        <div className={ styles['inf-field'] }>{ DIALOGSTITLES.TAXI_INFO.DATE }: {`${dateUtcFormat(EventDate, ACTION_IN_DATE_TIME_PATTERN)}`}</div>
      </div>
    );
  })

  renderAutocompleteInput = () => {
    const { company } = this.state;

    return (
      <div className={ styles['col-1-4'] }>
        <div className={ styles['autocomplete-input'] }>
          <label>Компания: (название или номер)</label>
          <Autocomplete
            menuStyle={ MENUSTYLE }
            value={ company.label }
            items={ company.suggests }
            getItemValue={ item => item.ShortCompanyName || item.CompanyName }
            onSelect={ (cValue, value) => this.handleSelectSuggest(cValue, value) }
            onChange={ (event, value) => this.handleChangeSuggest(value) }
            renderItem={ item =>
              (<div className='autocomplete-item'>
                { `${item.ShortCompanyName || item.CompanyName} (${item.CompanyId})` }
              </div>)
            }
          />
        </div>
      </div>
    );
  };

  renderDialog = (field, mailText, onClick, text, closingDocumentsHtml = null) => {
    const { waitResMove } = this.props;

    return (
      <Dialog onClick={ () => this.closeDialog(field) }>
        <div className={ styles['user-dialog'] }>
          <div className={ styles['closing-list'] }>
            { mailText }
            { closingDocumentsHtml }
            { text }
          </div>
          { this.renderAutocompleteInput() }
          <div className={ `${styles.row} ${styles.right}` }>
            <DecisionButtons
              waitingResponse={ waitResMove }
              disabled={ waitResMove }
              onCancel={ () => this.closeDialog(field) }
              onSave={ onClick }
              labelSave={ DECISIONBUTTONS.LABELS.MOVEANYWAY }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  renderConfirmMoveVouchersDialog = () => {
    const { confirmMoveVouchersDialog } = this.state;
    const {
      waitResMove,
      onAgreeWithConditions,
      selectedSubAccount,
      onChangeSubAccount,
      operationsInf,
      subAccounts,
      selectedCompanyId,
    } = this.props;

    const subAccountsItems = subAccounts
      .filter(({ CompanyId }) => selectedCompanyId !== CompanyId)
      .map(account => ({
        value: account.CompanyId,
        label: `${account.CompanyName} (${account.CompanyId})`,
      })
    );

    const sumTaxiOperations = operationsInf.length ? operationsInf.map(({ Amount }) => Amount).reduce((prev, next) => prev + next) : 0;

    if (confirmMoveVouchersDialog) {
      return (
        <Dialog onClick={ () => this.closeDialog(DIALOGS.CONFIRM_MOVE_OPERATIONS) }>
          <div className={ styles['user-dialog'] }>
            <div className={ styles['move-taxi'] }>{ DIALOGSTITLES.MOVE_VOUCHER_OPERATIONS }</div>
            <div className={ styles['move-taxi'] }>{ this.renderOperationsInf() }</div>
            <div className={ styles['move-taxi'] }>
              { DIALOGSTITLES.FULL_SUM_TAXI } { MoneyFormat.symbolWithMoneyWithDecimal(sumTaxiOperations) }
            </div>
            <div className={ styles['move-taxi'] }>
              <Select
                items={ subAccountsItems }
                value={ selectedSubAccount }
                theme={ COMPONENTS.SELECT.THEME.BORDER }
                onChange={ onChangeSubAccount }
              />
            </div>
            <div className={ styles['move-taxi'] }>{ this.renderCheckbox() }</div>
            <div className={ `${styles.row} ${styles.right}` }>
              <DecisionButtons
                waitingResponse={ waitResMove }
                onCancel={ () => this.closeDialog(DIALOGS.CONFIRM_MOVE_OPERATIONS) }
                onSave={ onAgreeWithConditions }
                labelSave={ DECISIONBUTTONS.LABELS.MOVE }
              />
            </div>
          </div>
        </Dialog>
      );
    }

    return null;
  }

  renderAgreeTextByOperations = () => {
    const { checkAllOperational, documentNumber } = this.props;

    const closingDocumentsHtml = documentNumber && documentNumber.length ?
      `${DIALOGSTITLES.ATTENTION_HAVE_DOCUMENTS}: ${documentNumber.join(',')}.` :
      null;

    if (checkAllOperational) {
      return (
        <div className={ styles['move-taxi'] }>{ DIALOGSTITLES.AGREE_WITH_CONDITIONS_BY_ALL_VOUCHER }</div>
      );
    }

    return (
      <div className={ styles['move-taxi'] }>
        { DIALOGSTITLES.AGREE_WITH_CONDITIONS } { closingDocumentsHtml }
        { DIALOGSTITLES.CANT_CANCEL }
      </div>
    );
  }

  renderAgreeWithConditionsDialog = () => {
    const { agreeWithConditionsDialog } = this.state;
    const { waitResMove, moveVouchers } = this.props;

    if (agreeWithConditionsDialog) {
      return (
        <Dialog onClick={ () => this.closeDialog(DIALOGS.AGREE_WITH_CONDITIONS) } width='500px'>
          <div className={ styles['user-dialog'] }>
            { this.renderAgreeTextByOperations() }
            <div className={ `${styles.row} ${styles.right}` }>
              <DecisionButtons
                waitingResponse={ waitResMove }
                onCancel={ () => this.closeDialog(DIALOGS.AGREE_WITH_CONDITIONS) }
                onSave={ moveVouchers }
                labelSave={ DECISIONBUTTONS.LABELS.MOVE }
              />
            </div>
          </div>
        </Dialog>
      );
    }

    return null;
  }

  renderCannotMoveVouchersDialog = () => {
    const { cannotMoveVouchersDialog } = this.state;
    const { resultTextMoveVouchers } = this.props;

    if (cannotMoveVouchersDialog) {
      return (
        <Dialog onClick={ () => this.closeDialog(DIALOGS.CANNOT_MOVE_VOUCHERS_DIALOG) }>
          <div className={ styles['user-dialog'] }>
            <div>{ resultTextMoveVouchers || DIALOGSTITLES.ERROR_MOVE_VOUCHER }</div>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button
                label={ COMPONENTS.BUTTON.THEME.LABEL }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ () => this.closeDialog(DIALOGS.CANNOT_MOVE_VOUCHERS_DIALOG) }
              />
            </div>
          </div>
        </Dialog>
      );
    }

    return null;
  }

  render() {
    const {
      cannotMoveTripDialog,
      dangerousMoveTripDialog,
      dangerousMoveTripOnlyIntegration,
      dangerousMoveTripWithIntegration,
      dialogWithIntegration,
      confirmMoveTripDialog,
      tripMovedDialog,
      cannotMoveOrdersDialog,
      dangerousMoveOrdersDialog,
      dangerousMoveOrdersOnlyIntegration,
      dangerousMoveOrdersWithIntegration,
      confirmMoveOrdersDialog,
      ordersMovedDialog,
      tripMovedDialogAlert,
      ordersMovedDialogAlert,
    } = this.state;

    const {
      closingDocuments,
      waitResMove,
      moveOrders,
      waitResChange,
      moveTrip,
      onClose,
      onNextStep,
    } = this.props;

    const mappingDocuments = () => closingDocuments.map((item, idx) => <div key={ idx }>{ item }</div>);

    const dangerousMoveTripHtml = dangerousMoveTripDialog && this.renderDialog(
      DIALOGS.DANGEROUS_MOVE.TRIP_DIALOG,
      DIALOGSTITLES.MOVE_NOT_POSSIBLE.TRIP,
      moveTrip,
      mappingDocuments()
    );

    const dangerousTripOnlyIntegrationHtml = dangerousMoveTripOnlyIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_MOVE.TRIP_DIALOG_ONLY_INTEGRATION,
      DIALOGSTITLES.TRIPATTENTION1C,
      moveTrip,
    );

    const dangerousTripWithIntegrationHtml = dangerousMoveTripWithIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_MOVE.TRIP_DIALOG_WITH_INTEGRATION,
      DIALOGSTITLES.MOVE_NOT_POSSIBLE.TRIP,
      moveTrip,
      DIALOGSTITLES.TRIPATTENTION1C,
      mappingDocuments()
    );

    const dangerousMoveOrdersHtml = dangerousMoveOrdersDialog && this.renderDialog(
      DIALOGS.DANGEROUS_MOVE.TRIP_ORDERS,
      DIALOGSTITLES.MOVE_NOT_POSSIBLE.ORDERS,
      moveOrders,
      mappingDocuments()
    );

    const dangerousOrdersOnlyIntegrationHtml = dangerousMoveOrdersOnlyIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_MOVE.ORDERS_DIALOG_ONLY_INTEGRATION,
      DIALOGSTITLES.TRIPATTENTION1C,
      moveOrders
    );

    const dangerousOrdersWithIntegrationHtml = dangerousMoveOrdersWithIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_MOVE.ORDERS_DIALOG_WITH_INTEGRATION,
      DIALOGSTITLES.MOVE_NOT_POSSIBLE.ORDERS,
      moveOrders,
      DIALOGSTITLES.TRIPATTENTION1C,
      mappingDocuments()
    );

    return (
      <div>
        { cannotMoveTripDialog && (
          <Dialog onClick={ () => this.closeDialog('cannotMoveTripDialog') }>
            <div className={ styles['user-dialog'] }>
              <div>Перенос поездки невозможен. Обратитесь в SD.</div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ () => this.closeDialog('cannotMoveTripDialog') }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { cannotMoveOrdersDialog && (
          <Dialog onClick={ () => this.closeDialog('cannotMoveOrdersDialog') }>
            <div className={ styles['user-dialog'] }>
              <div>Перенос заказов невозможен. Обратитесь в SD.</div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ () => this.closeDialog('cannotMoveOrdersDialog') }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { tripMovedDialog && (
          <Dialog onClick={ this.closeTripMovedDialog }>
            <div className={ styles['user-dialog'] }>
              <div>Поездка перенесена.</div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.closeTripMovedDialog }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { ordersMovedDialog && (
          <Dialog onClick={ this.closeOrdersMovedDialog }>
            <div className={ styles['user-dialog'] }>
              <div>Заказы перенесены.</div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.closeOrdersMovedDialog }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { tripMovedDialogAlert && (
          <Dialog onClick={ () => this.closeDialog('tripMovedDialogAlert') }>
            <div className={ styles['user-dialog'] }>
              <div className={ styles['closing-list'] }>
                <div>Закрывающие документы за этот период уже сформированы в целевой компании:</div><br />
                { mappingDocuments() }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.closeTripMovedDialog }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { ordersMovedDialogAlert && (
          <Dialog onClick={ () => this.closeDialog('ordersMovedDialogAlert') }>
            <div className={ styles['user-dialog'] }>
              <div className={ styles['closing-list'] }>
                <div>Закрывающие документы за этот период уже сформированы в целевой компании:</div><br />
                { mappingDocuments() }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.closeOrdersMovedDialog }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { dangerousMoveTripHtml }
        { dangerousTripOnlyIntegrationHtml }
        { dangerousTripWithIntegrationHtml }
        { dangerousMoveOrdersHtml }
        { dangerousOrdersOnlyIntegrationHtml }
        { dangerousOrdersWithIntegrationHtml }

        { confirmMoveTripDialog && (
          <Dialog onClick={ () => this.closeDialog('confirmMoveTripDialog') }>
            <div className={ styles['user-dialog'] }>
              { this.renderAutocompleteInput() }
              <div className={ `${styles.row} ${styles.right}` }>
                <DecisionButtons
                  waitingResponse={ waitResMove }
                  disabled={ waitResMove }
                  onCancel={ () => this.closeDialog('confirmMoveTripDialog') }
                  onSave={ moveTrip }
                  labelSave={ DECISIONBUTTONS.LABELS.MOVE }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { confirmMoveOrdersDialog && (
          <Dialog onClick={ () => this.closeDialog('confirmMoveOrdersDialog') }>
            <div className={ styles['user-dialog'] }>
              { this.renderAutocompleteInput() }
              <div className={ `${styles.row} ${styles.right}` }>
                <DecisionButtons
                  waitingResponse={ waitResMove }
                  disabled={ waitResMove }
                  onCancel={ () => this.closeDialog('confirmMoveOrdersDialog') }
                  onSave={ moveOrders }
                  labelSave={ DECISIONBUTTONS.LABELS.MOVE }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { dialogWithIntegration && (
          <Dialog onClick={ () => this.closeDialog(DIALOGS.DIALOGWIHTINTEGRATION) }>
            <div className={ styles['user-dialog'] }>
              <div className={ styles['closing-list'] }>
                { DIALOGSTITLES.TRIPATTENTION1C }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <DecisionButtons
                  waitingResponse={ waitResChange }
                  disabled={ waitResChange }
                  onCancel={ () => {
                    onClose();
                    this.closeDialog(DIALOGS.DIALOGWIHTINTEGRATION);
                  } }
                  onSave={ () => {
                    onNextStep();
                    this.closeDialog(DIALOGS.DIALOGWIHTINTEGRATION);
                  } }
                  labelSave={ DECISIONBUTTONS.LABELS.CHANGEANYWAY }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { this.renderConfirmMoveVouchersDialog() }
        { this.renderAgreeWithConditionsDialog() }
        { this.renderCannotMoveVouchersDialog() }
      </div>
    );
  }
}

export default MoveItemDialogs;
