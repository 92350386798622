import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import { Button, Tooltip } from 'sw-ui';

import { CurrencyInfoBlock } from '../hotel/components/CurrencyInfoBlock';
import { FlatButton } from '../../../../components/button';
import AjaxButton from '../../../../components/ajaxButton';
import { EditVatVersion } from './components/EditVatVersion';

import { formatDate, isMoment } from '../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../bi/utils/money';
import { actualIntegrationVersion, getNumberVoucher } from '../../../../bi/utils/trip';
import { getMaxRate, getSumVatAmount } from '../../../../bi/utils/hotels';

import {
  FORMATDATETIME,
  LOCALERU,
  FULLTIME,
} from '../../../../constants/time';
import { STATUSCODES, TRIPSTATUS, STATUSES_EXPLANATIONS, CUSTOM_TYPES } from '../../../../bi/constants/trips';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import COMPONENTS from '../../../../bi/constants/components';
import { HOTEL_PROVIDER_VALUE } from '../../../../bi/constants/hotel';

import styles from '../../styles/trip.module.scss';

const AUTO = 'Auto';

const LABELS = {
  DOWNLOADOLDVERSION: 'СКАЧАТЬ ВЕРСИЮ ВАУЧЕРА',
  SENDOLDVERSION: 'ОТПРАВИТЬ ВЕРСИЮ ВАУЧЕРА',
  AUTO: 'Автоматически',
  ADJUST_CANCELLATION: 'СКОРРЕКТИРОВАТЬ ОТМЕНУ',
  RESEND: 'Отправить',
  DOWNLOADXML: 'Скачать xml',
  DELETEVERSION: 'УДАЛИТЬ ВЕРСИЮ',
  PRICEFETAILSVERSION: 'ПОСМОТРЕТЬ ВЕРСИЮ',
  ERROR_DATE_MESSAGE: 'Введите корректную дату в формате DD.MM.YYYY HH:MM',
  CREATED_ORDER: 'Создан заказ',
  CHANGE_NUMBER_TICKET: 'Изменить номер билета',
  REPORT_AMOUNT: 'По счету:',
  PRICE: 'Цена поставщика:',
  COMMISSION: 'Сбор SW:',
};

const getShowVat = (items, item) => {
  if (item.Status === STATUSCODES.METADATA
    || item.Status === STATUSCODES.HIDDEN
    || item.ServiceType !== SERVICETYPE.HOTEL) return false;

  const itemsIds = items.filter(({ InternalVat: { VatReady } }) => !VatReady).map(itemEl => itemEl.Id);
  const minId = Math.min(...itemsIds);

  return !item.InternalVat.VatReady && minId === item.Id;
};

const VersionItemChange = ({
  opts,
  tripItem,
  tripItems,
  tripItemInd,
  versionItem,
  closingDocument,
  setShowModalDialog,
  isFlagEditTripItemVersion,
  isFlagEditTicketNumberCurrentPeriod,
  isFlagEditTicketNumberClosingPeriod,
}) => {
  const date = formatDate(versionItem.eventDate, FORMATDATETIME);

  const [dateValue, setDateValue] = useState(date);
  const [dateError, setDateError] = useState('');
  const [hiddenCurrentDatePicker, setHiddenCurrentDatePicker] = useState(false);

  const handleChangeDatePicker = value => setDateValue(value);

  const handleFocusDatePicker = () => {
    const { hiddenAllVersionDate } = opts;

    setHiddenCurrentDatePicker(true);
    hiddenAllVersionDate(true);
  };

  const handleBlurDatePicker = (value) => {
    const {
      tripId,
      saveVersionDateData,
      item: {
        ActualVersion,
      },
    } = opts;

    const jsonData = JSON.parse(ActualVersion.JsonData);
    const numberVoucher = jsonData && jsonData.TicketsExtended ? getNumberVoucher(jsonData.TicketsExtended) : jsonData.TicketNumber;
    const dateValid = isMoment(value);

    if (!dateValid) {
      return setDateError(!dateValid);
    }

    setDateError('');

    const data = {
      tripItemId: tripItem.TripItemId,
      tripItemVersionId: tripItem.Id,
      tripId,
      EventDate: value.format(FULLTIME),
      numberVoucher,
    };

    return saveVersionDateData(data);
  };

  const handlerAdjustCancellation = () => {
    opts.checkActualVersionFromConfirm(tripItem.TripItemId);
    opts.onHandlerAdjustCancellation({
      ...tripItem,
      OrderTripItemId: opts.item.ActualVersion.OrderTripItemId,
    });
  };

  const handleEditVat = ({ item, Rate, Amount, RackRate }) => {
    opts.onEditVatVersion({ item, rate: Rate, amount: Amount, rackRate: RackRate });
  };

  const renderPriceDetailsButton = id => (
    <div>
      <Button
        label={ LABELS.PRICEFETAILSVERSION }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ () => opts.onUpdateVersionButtons(id) }
      />
    </div>
  );

  const renderIntegration = (integrationVersion, waitResSendIntegration, onSendVersion, onDownloadVersion) => {
    const { EventDate, Guid, TripItemVersionId } = integrationVersion;

    return (
      <div className={ styles.right__block }>
        <div className={ styles.integration_version__date }>{ formatDate(EventDate, FORMATDATETIME) }</div>
        <div>
          <FlatButton
            label={ LABELS.RESEND }
            loading={ waitResSendIntegration === TripItemVersionId }
            onClick={ () => onSendVersion(TripItemVersionId) }
          />
        </div>
        <div>
          <FlatButton label={ LABELS.DOWNLOADXML } onClick={ () => onDownloadVersion(Guid) } />
        </div>
      </div>
    );
  };


  const renderOldVersionButtons = (onDownloadOldVoucher, onSendOldVoucher, disableSendVouchers) => {
    const renderSend = () => {
      if (!tripItem.Employees.length) {
        return null;
      }

      return (
        <Button
          disabled={ disableSendVouchers }
          label={ LABELS.SENDOLDVERSION }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ () => onSendOldVoucher(tripItem) }
        />
      );
    };

    return (
      <div className={ styles.right__block }>
        <Button
          label={ LABELS.DOWNLOADOLDVERSION }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ () => onDownloadOldVoucher(tripItem) }
        />
        { renderSend() }
      </div>
    );
  };

  const renderAdjustCancellation = () => (
    <div>
      <AjaxButton
        label={ LABELS.ADJUST_CANCELLATION }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        loading={ opts.waitResSaveTrip }
        onClick={ () => handlerAdjustCancellation(tripItem, opts) }
      />
    </div>
  );

  const renderEditVatVersion = (item) => {
    const { hideExtraDeleteButton } = versionItem;

    const showVat = getShowVat(tripItems, item);

    if (!showVat || hideExtraDeleteButton) return null;

    const amount = getSumVatAmount(item, tripItems);
    const rate = getMaxRate(item, tripItems);

    return (
      <EditVatVersion
        loading={ opts.waitResSaveVat }
        item={ item }
        amount={ amount }
        rate={ rate }
        providerName={ item.ProviderName }
        onConfirm={ ({ Amount, RackRate, Rate }) =>
          handleEditVat({ item, Rate, Amount, RackRate })
        }
      />
    );
  };

  const renderDeleteActualVersionButton = (tripItemId, checkActualVersion) => (
    <div>
      <Button
        label={ LABELS.DELETEVERSION }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ () => checkActualVersion(tripItemId) }
      />
    </div>
  );

  const renderDatePicker = () => (
    <div>
      {!hiddenCurrentDatePicker && opts.isShowAllVersionDate ? <p>{date}</p> :
      <DatePicker
        className={ styles.date }
        dateFormat={ FORMATDATETIME }
        timeFormat={ false }
        locale={ LOCALERU }
        value={ dateValue }
        onChange={ value => handleChangeDatePicker(value) }
        onBlur={ value => handleBlurDatePicker(value) }
        onFocus={ handleFocusDatePicker }
      />
      }
    </div>
    );

  const renderCurrencyInfo = () => {
    const {
      item: {
        ActualVersion: {
          ProviderName,
        },
      },
    } = opts;
    const { CurrencyInfo } = tripItem;

    const condition =
      ProviderName === HOTEL_PROVIDER_VALUE.tripcom ||
      ProviderName === HOTEL_PROVIDER_VALUE.expedia;

    if (!condition || !CurrencyInfo) return null;

    return (
      <CurrencyInfoBlock
        wrapperClassName={ styles['col-1-5'] }
        currency={ CurrencyInfo }
      />
    );
  };

  const renderReportAmount = () => {
    const {
      item: {
        Versions,
      },
    } = opts;
    const { ProviderName } = Versions[0];
    const { ReportAmount, ServiceType, JsonData } = Versions[Versions.length - 1];
    const { Type } = JSON.parse(JsonData);

    const isNotHotelOrCustom = ServiceType !== SERVICETYPE.HOTEL && ServiceType !== SERVICETYPE.CUSTOM;
    const isNotHotelAA = ServiceType === SERVICETYPE.HOTEL && ProviderName !== HOTEL_PROVIDER_VALUE.aanda;
    const isNotCustomAA = ServiceType === SERVICETYPE.CUSTOM
      && ProviderName !== HOTEL_PROVIDER_VALUE.aanda
      && Type !== CUSTOM_TYPES.GROUP_ACCOMODATION;

    if (isNotHotelOrCustom
      || isNotHotelAA
      || isNotCustomAA
      || tripItemInd !== Versions.length - 1
      || !ReportAmount) return null;

    return (
      <div>
        { LABELS.REPORT_AMOUNT } { MoneyFormat.moneyWithDecimal(ReportAmount || 0, true) }
      </div>
    );
  };

  const {
    hideExtraDeleteButton,
    description,
    price,
    user,
    idDetails,
  } = versionItem;
  const {
    integration,
    waitResSendIntegration,
    onSendVersion,
    onDownloadVersion,
    integrationVersions,
    prohibitionVouchers,
    checkActualVersion,
    tripChange1C,
    onDownloadOldVoucher,
    onSendOldVoucher,
    statusBook,
  } = opts;
  const { Id, TripItemId, OperationDetailsId, JsonData } = tripItem;

  const disabledSendVouchers = !!prohibitionVouchers.length
      && prohibitionVouchers.some(prohibitionId => prohibitionId === tripItems[0].CompanyId);

  const integrationVersion = actualIntegrationVersion(integrationVersions, Id);
  const isUploadedIntegrationVersion = integrationVersion && integrationVersion.Uploaded;
  const UploadedAndFlags = tripChange1C || !isUploadedIntegrationVersion;

  const deleteActualVersionButton = tripItems.length > 1 && tripItems[0].Id === Id && UploadedAndFlags && renderDeleteActualVersionButton(TripItemId, checkActualVersion);

  const oldVersionButtons = tripItems.length > 1 && tripItems[0].Id !== Id && renderOldVersionButtons(onDownloadOldVoucher, onSendOldVoucher, disabledSendVouchers);

  const adjustCancellation = tripItem.Penalties.length &&
    statusBook === TRIPSTATUS.AWAITINGCONFIRMATION &&
    tripItem.ServiceType === SERVICETYPE.HOTEL &&
    tripItem.StatusExplanation === STATUSES_EXPLANATIONS.CANCELED &&
    tripItem.ProviderName !== HOTEL_PROVIDER_VALUE.expedia &&
    tripItem.ProviderName !== HOTEL_PROVIDER_VALUE.tripcom
      ? renderAdjustCancellation(tripItem)
      : null;

  const isCreatedOrderVersion = description === LABELS.CREATED_ORDER;
  const isAir = tripItem.ServiceType === SERVICETYPE.AIR;
  const isTrain = tripItem.ServiceType === SERVICETYPE.TRAIN;
  const isCustom = tripItem.ServiceType === SERVICETYPE.CUSTOM;
  const isTransfer = tripItem.ServiceType === SERVICETYPE.TRANSFER;
  const isHotel = tripItem.ServiceType === SERVICETYPE.HOTEL;
  const isBus = tripItem.ServiceType === SERVICETYPE.BUS;

  const isAirAdditional = tripItem.ServiceType === SERVICETYPE.AIR_ADDITIONAL_SERVICE;

  const json = JSON.parse(JsonData);

  const isAwaitingConfirmation = statusBook === TRIPSTATUS.AWAITINGCONFIRMATION;
  const showInfoAirTicket = isAir && isAwaitingConfirmation;

  const commission = isAir && MoneyFormat.symbolWithMoney(Math.abs(json.PriceDetails.Commission));
  const fee = isAir && MoneyFormat.symbolWithMoney(Math.abs(json.PriceDetails.Fee));
  const actualButton = !hideExtraDeleteButton ? deleteActualVersionButton : null;
  const adjustCancellationButton = !hideExtraDeleteButton ? adjustCancellation : null;
  const changeVatButton = renderEditVatVersion(tripItem);
  const priceText = price !== 0 || isAir ? MoneyFormat.symbolWithMoneyWithDecimal(Math.abs(price)) : '';

  const isShowPriceDetails = isCustom || isTransfer || isHotel || isBus;
  const { PriceDetails } = json;

  const basePrice = (isCreatedOrderVersion && isShowPriceDetails) ? PriceDetails.Base : versionItem.base;
  const commissionSWPrice = (isCreatedOrderVersion && isShowPriceDetails) ? PriceDetails.Commission : versionItem.commission;

  const base = (isShowPriceDetails && !!basePrice) ? MoneyFormat.symbolWithMoneyWithDecimal(basePrice) : '';
  const commissionSW = (isShowPriceDetails && !!commissionSWPrice) ? MoneyFormat.symbolWithMoneyWithDecimal(commissionSWPrice) : '';

  const userText = user || '';
  const integrationHtml = integration && integrationVersion && renderIntegration(integrationVersion, waitResSendIntegration, onSendVersion, onDownloadVersion);

  const getVersionNumber = idx => `${tripItems.length - idx}. `;
  const airPriceDetailsButtonHtml = idDetails && isAir && !isCreatedOrderVersion && renderPriceDetailsButton(idDetails);
  const canChangeVersionDate = isFlagEditTripItemVersion && (isAir || isTrain || isAirAdditional);
  const versionDate = !canChangeVersionDate ? dateValue : '';
  const showVersionChangeDate = canChangeVersionDate && idDetails ? renderDatePicker() : versionDate;
  const isChangeTicketNumberAirClosingPeriodAvailable = isAir && isFlagEditTicketNumberClosingPeriod;
  const isChangeTicketNumberAirCurrentPeriodAvailable = isAir
    && (
      isFlagEditTicketNumberCurrentPeriod
      && (Array.isArray(closingDocument) && !closingDocument.length)
  );

  const infoTicketCommission = showInfoAirTicket && (
    <div className={ `${styles.fee}` }>
      { commission }
    </div>
  );

  const infoTicketFee = showInfoAirTicket && (
    <div className={ `${styles.fee}` }>
      { fee }
    </div>
  );

  const renderBase = () => (base && <div>{LABELS.PRICE} {base}</div>);
  const renderCommission = () => (commissionSW && <div>{LABELS.COMMISSION} {commissionSW}</div>);

  const renderButtonChangeTicketNumber = () => {
    if (isChangeTicketNumberAirCurrentPeriodAvailable || isChangeTicketNumberAirClosingPeriodAvailable) {
      return (
        <div className={ `sw-tooltip-wrapper ${styles.wrapper_version_icon}` }>
          <button className={ styles.button } onClick={ () => setShowModalDialog(true) }>
            <i className='material-icons'>edit</i>
          </button>
          <Tooltip
            position={ 'right' }
          >
            { LABELS.CHANGE_NUMBER_TICKET }
          </Tooltip>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={ styles.row } key={ `${tripItem.Id}_${price}` }>
      <div className={ `${styles.row} ${styles['col-1-3']}` }>
        <div className={ styles['col-1-4'] }>
          { Id }
        </div>
        <div className={ styles['col-1-4'] }>
          { OperationDetailsId }
        </div>
        <div className={ `${styles['col-2-4']} ${styles['icon-row']} ${styles.version_icon}` }>
          <div>
            { getVersionNumber(tripItemInd) }
            { description }
          </div>
          { renderButtonChangeTicketNumber()}
        </div>
      </div>
      <div className={ `${styles.row} ${styles['col-2-3']}` }>
        <div className={ `${styles['col-1-4']} ${styles['user-name']}` }>
          { userText === AUTO ? LABELS.AUTO : userText }
        </div>
        <div className={ `${styles['col-1-6']} ${styles['col-date']}` }>
          { showVersionChangeDate }
          { dateError && idDetails && <p className={ styles.error }>{LABELS.ERROR_DATE_MESSAGE}</p> }
        </div>
        <div className={ `${styles['col-1-4']} ${styles.price}` }>
          <div>{ priceText }</div>
          { renderBase() }
          { renderCommission() }
          { renderReportAmount() }
        </div>
        { renderCurrencyInfo() }
        { infoTicketCommission }
        { infoTicketFee }
        <div className={ `${styles.right__block} ${styles.last_right_elem}` } >
          { integrationHtml }
          { oldVersionButtons }
          { actualButton }
          { airPriceDetailsButtonHtml }
          { adjustCancellationButton }
          { changeVatButton }
        </div>
      </div>
    </div>
  );
};

VersionItemChange.propTypes = {
  versionItem: PropTypes.object.isRequired,
  tripItem: PropTypes.object.isRequired,
  tripItems: PropTypes.array.isRequired,
  tripItemInd: PropTypes.number.isRequired,
  opts: PropTypes.object.isRequired,
  setShowModalDialog: PropTypes.func.isRequired,
  closingDocument: PropTypes.array,
  isFlagEditTripItemVersion: PropTypes.bool,
  isFlagEditTicketNumberCurrentPeriod: PropTypes.bool,
  isFlagEditTicketNumberClosingPeriod: PropTypes.bool,
};

VersionItemChange.defaultProps = {
  closingDocument: null,
  isFlagEditTripItemVersion: false,
  isFlagEditTicketNumberCurrentPeriod: false,
  isFlagEditTicketNumberClosingPeriod: false,
};

export { VersionItemChange };
