import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { VersionItem } from './VersionItem';

import styles from '../../styles/trip.module.scss';

class History extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    opts: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const items = this.filterItems(this.props.items);

    this.state = {
      items,
      cancelDialog: false,
      cancelItem: null,
      changedDate: null,
    };
  }

  componentDidMount() {
    const { opts: { getStatusEditLegalEntity, item } } = this.props;

    getStatusEditLegalEntity(item.ActualVersion.TripItemId);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newItems = this.filterItems(newProps.items);
    this.setState({ items: newItems });
  }

  filterItems = (items) => {
    const Routes = JSON.parse(items[0].JsonData).Routes;

    if (!Routes) return items;

    let price = 0;
    let AdditionalServiceCount = 0;
    const newItems = items;
    let AdditionalServicesDescription = '(';

    Routes.forEach((route, indexRoute) => {
      route.Segments.forEach((segment, indexSegment) => {
        AdditionalServicesDescription += `${segment.DepartureCity} - ${segment.ArrivalCity}`;

        if (indexRoute !== Routes.length - 1 || indexSegment !== route.Segments.length - 1) {
          AdditionalServicesDescription += ', ';
        }
      });
    });

    AdditionalServicesDescription += ')';

    for (let i = newItems.length - 1; i >= 0; i--) {
      const json = JSON.parse(newItems[i].JsonData);
      const AdditionalServices = json.AdditionalServices || [];
      const allServicesPrice = AdditionalServices.reduce((acc, additionalService) =>
        acc + additionalService.Service.Price + additionalService.Service.Comission
        , 0);

      const AdditionalServicesPrice = allServicesPrice - price;
      const AdditionalServiceLength = AdditionalServices.length - AdditionalServiceCount;
      AdditionalServiceCount += AdditionalServiceLength;
      price += AdditionalServicesPrice;
      newItems[i].AdditionalServicesPrice = AdditionalServicesPrice;
      newItems[i].AdditionalServiceLength = AdditionalServiceLength;
      newItems[i].AdditionalServicesDescription = AdditionalServicesDescription;
    }

    return newItems;
  }

  renderTripItems = () => {
    const { items, opts } = this.props;

    const tripItems = items.map((item, ind) => (
      <VersionItem
        tripItem={ item }
        tripItemInd={ ind }
        tripItems={ items }
        opts={ opts }
        key={ item.Id }
      />
    ));

    return tripItems;
  };

  render() {
    return (
      <div className={ styles.history }>
        { this.renderTripItems() }
      </div>
    );
  }
}

export default History;
